const { widgetUrl, esfUrl, msemUrl } = __ESF_CONFIG__;

window.pagePanier = "/panier";
window.pageEsf = "/";
window.channel = "ESF";
window.callbackEsf = undefined;
window.cartChanged = undefined;
window.skipRecap = undefined;

function openBoutiqueESF(params) {
  return load().then(() => start(params));
}

function load() {
  let script = document.querySelector(`[src="${msemUrl}"]`);
  return new Promise((resolve) => {
    if (script) return resolve();
    script = document.createElement("script");
    script.src = msemUrl;
    script.onload = resolve;
    document.head.appendChild(script);
  });
}

function start(params) {
  try {
    const data = JSON.parse(decodeURI(params));
    const options = {
      resort: Number(data.school),
      channel: window.channel,
      lang: data.lang,
      intermediary: data.intermediary,
      preview: window.CmsConfig ? true : undefined,
      cartUrl: window.pagePanier,
      analytics: window.analytics,
      callback: window.callbackEsf,
      cartChanged: window.cartChanged,
      skipRecap: window.skipRecap,
    };
    window.MseM.onLoad(() => {
      window.MseM.esf(options, data.presets);
    });
  } catch (e) {}
}

window.openBoutiqueESF = openBoutiqueESF;

// ------
// TODO : Vérifier si on peut supprimer la suite
// ------
window.currentLang = "fr";
window.cornerHost = widgetUrl;
window.apiHost = widgetUrl;

function convertURLVel3toPresets(url, cb) {
  const arr = url.split("/");
  const slug = arr.length === 5 ? arr[3] : {};
  return getResort(slug)
    .then((resort) => resort.code)
    .then((code) => cb({ school: code }));
}
function getResort(slug) {
  return fetch(`${esfUrl}/resort-code/${slug}`, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  }).then((response) => {
    if (!response.ok) {
      return Promise.reject("error");
    }
    return response.json();
  });
}
window.ESFPlus = window.ESFPlus || {
  convertURLVel3toPresets,
};
